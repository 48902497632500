const formations = [
    {
        "nameF": "COMPLET",
        "title": "CODE ETG",
        "description": "Épreuve théorique générale",
        "totalPriceFor": 400,
        "servicesinF": [
            {
                "idS": "65f48684009c4b401e7fd976",
                "nom": "Frais de dossier",
                "tarifNormal": 100,
                "tarifReduit": 90,
                "tva": 20,
                "nomP": "Frais de dossiers",
                "typeS": "administratif",
                "etg": "oui",
                "categories": [
                    "CODE ETG",
                    "CODE ETM"
                ],
                "quantity": 1,
                "useReducedRate": false,
                "totalPrice": 100
            },
            {
                "idS": "65f48684009c4b401e7fd978",
                "nom": "Pochette pédagogique",
                "tarifNormal": 50,
                "tarifReduit": 22,
                "tva": 5.5,
                "nomP": "Pochette pédagogiques",
                "typeS": "administratif",
                "etg": "oui",
                "categories": [
                    "CODE ETG",
                    "CODE ETM"
                ],
                "quantity": 1,
                "useReducedRate": false,
                "totalPrice": 50
            },
            {
                "idS": "65f48684009c4b401e7fd97a",
                "nom": "E-code formation (4 mois)",
                "tarifNormal": 60,
                "tarifReduit": 50,
                "tva": 20,
                "nomP": "E-code formation (4 mois)s",
                "typeS": "theorie",
                "etg": "oui",
                "categories": [
                    "CODE ETG",
                    "CODE ETM"
                ],
                "quantity": 1,
                "useReducedRate": true,
                "totalPrice": 50
            },
            {
                "idS": "65f48684009c4b401e7fd979",
                "nom": "Forfait code salle (4 mois)",
                "tarifNormal": 200,
                "tarifReduit": 76.5,
                "tva": 20,
                "nomP": "Forfait code salle (4 mois)s",
                "typeS": "theorie",
                "etg": "oui",
                "categories": [
                    "CODE ETG",
                    "CODE ETM"
                ],
                "quantity": 1,
                "useReducedRate": false,
                "totalPrice": 200
            }
        ],
        "categorie": "ETG",
        "type": "voiture"
    },
    {
        "nameF": "EN SALLE",
        "title": "CODE ETG",
        "description": "Épreuve théorique générale",
        "totalPriceFor": 300,
        "servicesinF": [
            {
                "idS": "65f48684009c4b401e7fd976",
                "nom": "Frais de dossier",
                "tarifNormal": 100,
                "tarifReduit": 90,
                "tva": 20,
                "nomP": "Frais de dossiers",
                "typeS": "administratif",
                "etg": "oui",
                "categories": [
                    "CODE ETG",
                    "CODE ETM"
                ],
                "quantity": 1,
                "useReducedRate": false,
                "totalPrice": 100
            },
            {
                "idS": "65f48684009c4b401e7fd979",
                "nom": "Forfait code salle (4 mois)",
                "tarifNormal": 200,
                "tarifReduit": 76.5,
                "tva": 20,
                "nomP": "Forfait code salle (4 mois)s",
                "typeS": "theorie",
                "etg": "oui",
                "categories": [
                    "CODE ETG",
                    "CODE ETM"
                ],
                "quantity": 1,
                "useReducedRate": false,
                "totalPrice": 200
            }
        ],
        "categorie": "ETG",
        "type": "voiture"
    },
    {
        "nameF": "EN LIGNE",
        "title": "CODE ETG",
        "description": "Épreuve théorique générale",
        "totalPriceFor": 200,
        "servicesinF": [
            {
                "idS": "65f48684009c4b401e7fd976",
                "nom": "Frais de dossier",
                "tarifNormal": 100,
                "tarifReduit": 90,
                "tva": 20,
                "nomP": "Frais de dossiers",
                "typeS": "administratif",
                "etg": "oui",
                "categories": [
                    "CODE ETG",
                    "CODE ETM"
                ],
                "quantity": 1,
                "useReducedRate": false,
                "totalPrice": 100
            },
            {
                "idS": "65f48684009c4b401e7fd978",
                "nom": "Pochette pédagogique",
                "tarifNormal": 50,
                "tarifReduit": 22,
                "tva": 5.5,
                "nomP": "Pochette pédagogiques",
                "typeS": "administratif",
                "etg": "oui",
                "categories": [
                    "CODE ETG",
                    "CODE ETM"
                ],
                "quantity": 1,
                "useReducedRate": false,
                "totalPrice": 50
            },
            {
                "idS": "65f48684009c4b401e7fd97a",
                "nom": "E-code formation (4 mois)",
                "tarifNormal": 60,
                "tarifReduit": 50,
                "tva": 20,
                "nomP": "E-code formation (4 mois)s",
                "typeS": "theorie",
                "etg": "oui",
                "categories": [
                    "CODE ETG",
                    "CODE ETM"
                ],
                "quantity": 1,
                "useReducedRate": true,
                "totalPrice": 50
            }
        ],
        "categorie": "ETG",
        "type": "voiture"
    },

    {
        "nameF": "EN LIGNE",
        "title": "CODE ETM",
        "description": "Épreuve théorique moto",
        "totalPriceFor": 200,
        "servicesinF": [
            {
                "idS": "65f48684009c4b401e7fd976",
                "nom": "Frais de dossier",
                "tarifNormal": 100,
                "tarifReduit": 90,
                "tva": 20,
                "nomP": "Frais de dossiers",
                "typeS": "administratif",
                "etg": "oui",
                "categories": [
                    "CODE ETG",
                    "CODE ETM"
                ],
                "quantity": 1,
                "useReducedRate": true,
                "totalPrice": 90
            },
            {
                "idS": "65f48684009c4b401e7fd978",
                "nom": "Pochette pédagogique",
                "tarifNormal": 50,
                "tarifReduit": 22,
                "tva": 5.5,
                "nomP": "Pochette pédagogiques",
                "typeS": "administratif",
                "etg": "oui",
                "categories": [
                    "CODE ETG",
                    "CODE ETM"
                ],
                "quantity": 1,
                "useReducedRate": false,
                "totalPrice": 50
            },
            {
                "idS": "65f48684009c4b401e7fd97a",
                "nom": "E-code formation (4 mois)",
                "tarifNormal": 60,
                "tarifReduit": 50,
                "tva": 20,
                "nomP": "E-code formation (4 mois)s",
                "typeS": "theorie",
                "etg": "oui",
                "categories": [
                    "CODE ETG",
                    "CODE ETM"
                ],
                "quantity": 1,
                "useReducedRate": false,
                "totalPrice": 60
            }
        ],
        "categorie": "ETM",
        "type": "2rouesETformations"
    },

    {
        nameF: "PERMIS BOITE AUTOMATIQUE",
        title: "ETG + CONDUITE CLASSIQUE AUTOMATIQUE",
        description: "SANS FORFAIT CODE",
        totalPriceFor: 990,
        servicesinF: [
            {
                idS: "65f48684009c4b401e7fd97f",
                nom: "Evaluation de départ",
                tarifNormal: 70,
                tarifReduit: 35,
                tva: 20,
                nomP: "Evaluation de départs",
                typeS: "pratique",
                categories: [
                    "B AUTO",
                    "B MANU",
                    "A1 A2",
                    "A1",
                    "A2",
                    "A",
                    "F125",
                    "AM cyclo",
                    "AM quadri",
                    "AAC"
                ],
                quantity: 1,
                useReducedRate: false,
                totalPrice: 70
            },
            {
                idS: "65f48684009c4b401e7fd983",
                nom: "Leçon de conduite automatique",
                tarifNormal: 70,
                tarifReduit: 65.38,
                tva: 20,
                nomP: "Leçon de conduite automatiques",
                typeS: "pratique",
                categories: [
                    "B AUTO"
                ],
                quantity: 13,
                useReducedRate: false,
                totalPrice: 850
            },
            {
                idS: "65f48684009c4b401e7fd981",
                nom: "Accompagnement examen conduite",
                tarifNormal: 70,
                tarifReduit: 38.5,
                tva: 20,
                nomP: "Accompagnement examen conduites",
                typeS: "pratique",
                categories: [
                    "B MANU",
                    "B AUTO",
                    "AAC"
                ],
                quantity: 1,
                useReducedRate: false,
                totalPrice: 70
            }
        ],
        categorie: "B AUTO",
        type: "voiture",
    },
    {
        "nameF": "PERMIS CS",
        "title": "CONDUITE AAC AUTOMATIQUE",
        "description": "SANS FORFAIT CODE",
        "totalPriceFor": 1060,
        "servicesinF": [
            {
                "idS": "65f48684009c4b401e7fd97f",
                "nom": "Evaluation de départ",
                "tarifNormal": 70,
                "tarifReduit": 35,
                "tva": 20,
                "nomP": "Evaluation de départs",
                "typeS": "pratique",
                "categories": [
                    "B AUTO",
                    "B MANU",
                    "A1 A2",
                    "A",
                    "F125",
                    "AM cyclo",
                    "AM quadri",
                    "AAC"
                ],
                "quantity": 1,
                "useReducedRate": false,
                "totalPrice": 70
            },
            {
                "idS": "65f48684009c4b401e7fd983",
                "nom": "Leçon de conduite automatique",
                "tarifNormal": 70,
                "tarifReduit": 68.38,
                "tva": 20,
                "nomP": "Leçon de conduite automatiques",
                "typeS": "pratique",
                "categories": [
                    "B AUTO"
                ],
                "quantity": 13,
                "useReducedRate": false,
                "totalPrice": 850
            },
            {
                "idS": "65f48684009c4b401e7fd987",
                "nom": "Rendez-vous préalable (2h)",
                "tarifNormal": 140,
                "tarifReduit": 90,
                "tva": 20,
                "nomP": "Rendez-vous préalable (2h)s",
                "typeS": "pratique",
                "categories": [
                    "AAC"
                ],
                "quantity": 1,
                "useReducedRate": false,
                "totalPrice": 140
            }
        ],
        "categorie": "B AUTO CS",
        "type": "voiture"
    },
    {
        "nameF": "PERMIS AAC",
        "title": "CONDUITE AAC AUTOMATIQUE",
        "description": "SANS FORFAIT CODE",
        "totalPriceFor": 1060,
        "servicesinF": [
            {
                "idS": "65f48684009c4b401e7fd97f",
                "nom": "Evaluation de départ",
                "tarifNormal": 70,
                "tarifReduit": 35,
                "tva": 20,
                "nomP": "Evaluation de départs",
                "typeS": "pratique",
                "categories": [
                    "B AUTO",
                    "B MANU",
                    "A1 A2",
                    "A",
                    "F125",
                    "AM cyclo",
                    "AM quadri",
                    "AAC"
                ],
                "quantity": 1,
                "useReducedRate": false,
                "totalPrice": 70
            },
            {
                "idS": "65f48684009c4b401e7fd983",
                "nom": "Leçon de conduite automatique",
                "tarifNormal": 70,
                "tarifReduit": 40,
                "tva": 20,
                "nomP": "Leçon de conduite automatiques",
                "typeS": "pratique",
                "categories": [
                    "B AUTO"
                ],
                "quantity": 13,
                "useReducedRate": false,
                "totalPrice": 850
            },
            {
                "idS": "65f48684009c4b401e7fd987",
                "nom": "Rendez-vous préalable (2h)",
                "tarifNormal": 140,
                "tarifReduit": 90,
                "tva": 20,
                "nomP": "Rendez-vous préalable (2h)s",
                "typeS": "pratique",
                "categories": [
                    "AAC"
                ],
                "quantity": 1,
                "useReducedRate": false,
                "totalPrice": 140
            }
        ],
        "categorie": "B AUTO AAC",
        "type": "voiture"
    },
    {
        "nameF": "PERMIS B MANUELLE",
        "title": "CONDUITE CLASSIQUE MANUELLE",
        "description": "SANS FORFAIT CODE",
        "totalPriceFor": 1290,
        "servicesinF": [
            {
                "idS": "65f48684009c4b401e7fd97f",
                "nom": "Evaluation de départ",
                "tarifNormal": 65,
                "tarifReduit": 35,
                "tva": 20,
                "nomP": "Evaluation de départs",
                "typeS": "pratique",
                "categories": [
                    "B AUTO",
                    "B MANU",
                    "A1 A2",
                    "A",
                    "F125",
                    "AM cyclo",
                    "AM quadri",
                    "AAC"
                ],
                "quantity": 1,
                "useReducedRate": false,
                "totalPrice": 65
            },
            {
                "idS": "65f48684009c4b401e7fd980",
                "nom": "Leçon de conduite",
                "tarifNormal": 65,
                "tarifReduit": 58,
                "tva": 20,
                "nomP": "Leçon de conduites",
                "typeS": "pratique",
                "categories": [
                    "B MANU",
                    "AAC"
                ],
                "quantity": 20,
                "useReducedRate": true,
                "totalPrice": 1160
            },
            {
                "idS": "65f48684009c4b401e7fd981",
                "nom": "Accompagnement examen conduite",
                "tarifNormal": 65,
                "tarifReduit": 38.5,
                "tva": 20,
                "nomP": "Accompagnement examen conduites",
                "typeS": "pratique",
                "categories": [
                    "B MANU",
                    "B AUTO",
                    "AAC"
                ],
                "quantity": 1,
                "useReducedRate": false,
                "totalPrice": 65
            }
        ],
        "categorie": "B MANU",
        "type": "voiture"
    },
    {
        "nameF": "PERMIS AAC",
        "title": "CONDUITE AAC MANUELLE",
        "description": "SANS FORFAIT CODE",
        "totalPriceFor": 1355,
        "servicesinF": [
            {
                "idS": "65f48684009c4b401e7fd97f",
                "nom": "Evaluation de départ",
                "tarifNormal": 65,
                "tarifReduit": 35,
                "tva": 20,
                "nomP": "Evaluation de départs",
                "typeS": "pratique",
                "categories": [
                    "B AUTO",
                    "B MANU",
                    "A1 A2",
                    "A",
                    "F125",
                    "AM cyclo",
                    "AM quadri",
                    "AAC"
                ],
                "quantity": 1,
                "useReducedRate": false,
                "totalPrice": 65
            },
            {
                "idS": "65f48684009c4b401e7fd980",
                "nom": "Leçon de conduite",
                "tarifNormal": 65,
                "tarifReduit": 58,
                "tva": 20,
                "nomP": "Leçon de conduites",
                "typeS": "pratique",
                "categories": [
                    "B MANU",
                    "AAC"
                ],
                "quantity": 20,
                "useReducedRate": true,
                "totalPrice": 1160
            },
            {
                "idS": "65f48684009c4b401e7fd987",
                "nom": "Rendez-vous préalable (2h)",
                "tarifNormal": 130,
                "tarifReduit": 90,
                "tva": 20,
                "nomP": "Rendez-vous préalable (2h)s",
                "typeS": "pratique",
                "categories": [
                    "AAC"
                ],
                "quantity": 1,
                "useReducedRate": false,
                "totalPrice": 130
            },
        ],
        "categorie": "B MANU AAC",
        "type": "voiture"
    },
    {
        "nameF": "PERMIS CS",
        "title": "CONDUITE CS MANUELLE",
        "description": "SANS FORFAIT CODE",
        "totalPriceFor": 1355,
        "servicesinF": [
            {
                "idS": "65f48684009c4b401e7fd97f",
                "nom": "Evaluation de départ",
                "tarifNormal": 65,
                "tarifReduit": 58,
                "tva": 20,
                "nomP": "Evaluation de départs",
                "typeS": "pratique",
                "categories": [
                    "B AUTO",
                    "B MANU",
                    "A1 A2",
                    "A",
                    "F125",
                    "AM cyclo",
                    "AM quadri",
                    "AAC"
                ],
                "quantity": 1,
                "useReducedRate": false,
                "totalPrice": 65
            },
            {
                "idS": "65f48684009c4b401e7fd980",
                "nom": "Leçon de conduite",
                "tarifNormal": 65,
                "tarifReduit": 58,
                "tva": 20,
                "nomP": "Leçon de conduites",
                "typeS": "pratique",
                "categories": [
                    "B MANU",
                    "AAC"
                ],
                "quantity": 20,
                "useReducedRate": true,
                "totalPrice": 1160
            },
            {
                "idS": "65f48684009c4b401e7fd987",
                "nom": "Rendez-vous préalable (2h)",
                "tarifNormal": 130,
                "tarifReduit": 90,
                "tva": 20,
                "nomP": "Rendez-vous préalable (2h)s",
                "typeS": "pratique",
                "categories": [
                    "AAC"
                ],
                "quantity": 1,
                "useReducedRate": false,
                "totalPrice": 130
            },
        ],
        "categorie": "B MANU CS",
        "type": "voiture"
    },
    {
        "nameF": "PERMIS MOTO A1",
        "title": "MOTO A1 (16 ANS) & A2 (18 ANS)",
        "description": "SANS FORFAIT CODE",
        "totalPriceFor": 1390,
        "servicesinF": [
            {
                "idS": "65f48684009c4b401e7fd97f",
                "nom": "Evaluation de départ",
                "tarifNormal": 65,
                "tarifReduit": 35,
                "tva": 20,
                "nomP": "Evaluation de départs",
                "typeS": "pratique",
                "categories": [
                    "B AUTO",
                    "B MANU",
                    "A1",
                    "A",
                    "F125",
                    "AM cyclo",
                    "AM quadri",
                    "AAC"
                ],
                "quantity": 1,
                "useReducedRate": false,
                "totalPrice": 65
            },
            {
                "idS": "65f48684009c4b401e7fd984",
                "nom": "Leçon de conduite Moto",
                "tarifNormal": 60,
                "tarifReduit": 54.75,
                "tva": 20,
                "nomP": "Leçon de conduite Motos",
                "typeS": "pratique",
                "categories": [
                    "AM cyclo",
                    "A",
                    "A1 A2",
                    "A1",
                    "A2",
                    "F125"
                ],
                "quantity": 20,
                "useReducedRate": true,
                "totalPrice": 1095
            },
            {
                "idS": "65f48684009c4b401e7fd985",
                "nom": "Accompagnement examen plateau",
                "tarifNormal": 100,
                "tarifReduit": 50,
                "tva": 20,
                "nomP": "Accompagnement examen plateaus",
                "typeS": "pratique",
                "categories": [
                    "A1 A2",
                    "A1",
                    "A2",
                ],
                "quantity": 1,
                "useReducedRate": false,
                "totalPrice": 100
            },
            {
                "idS": "65f48684009c4b401e7fd986",
                "nom": "Accompagnement examen circulation",
                "tarifNormal": 130,
                "tarifReduit": 50,
                "tva": 20,
                "nomP": "Accompagnement examen circulations",
                "typeS": "pratique",
                "categories": [
                    "A1 A2",
                    "A1",
                    "A2",
                ],
                "quantity": 1,
                "useReducedRate": false,
                "totalPrice": 130
            }
        ],
        "categorie": "A1",
        "type": "2rouesETformations"
    },
    {
        "nameF": "PERMIS MOTO A2",
        "title": "MOTO A1 (16 ANS) & A2 (18 ANS)",
        "description": "SANS FORFAIT CODE",
        "totalPriceFor": 1390,
        "servicesinF": [
            {
                "idS": "65f48684009c4b401e7fd97f",
                "nom": "Evaluation de départ",
                "tarifNormal": 65,
                "tarifReduit": 35,
                "tva": 20,
                "nomP": "Evaluation de départs",
                "typeS": "pratique",
                "categories": [
                    "B AUTO",
                    "B MANU",
                    "A1",
                    "A",
                    "F125",
                    "AM cyclo",
                    "AM quadri",
                    "AAC"
                ],
                "quantity": 1,
                "useReducedRate": false,
                "totalPrice": 65
            },
            {
                "idS": "65f48684009c4b401e7fd984",
                "nom": "Leçon de conduite Moto",
                "tarifNormal": 60,
                "tarifReduit": 54.75,
                "tva": 20,
                "nomP": "Leçon de conduite Motos",
                "typeS": "pratique",
                "categories": [
                    "AM cyclo",
                    "A",
                    "A1 A2",
                    "A1",
                    "A2",
                    "F125"
                ],
                "quantity": 20,
                "useReducedRate": true,
                "totalPrice": 1095
            },
            {
                "idS": "65f48684009c4b401e7fd985",
                "nom": "Accompagnement examen plateau",
                "tarifNormal": 100,
                "tarifReduit": 50,
                "tva": 20,
                "nomP": "Accompagnement examen plateaus",
                "typeS": "pratique",
                "categories": [
                    "A1 A2",
                    "A1",
                    "A2",
                ],
                "quantity": 1,
                "useReducedRate": false,
                "totalPrice": 100
            },
            {
                "idS": "65f48684009c4b401e7fd986",
                "nom": "Accompagnement examen circulation",
                "tarifNormal": 130,
                "tarifReduit": 50,
                "tva": 20,
                "nomP": "Accompagnement examen circulations",
                "typeS": "pratique",
                "categories": [
                    "A1 A2",
                    "A1",
                    "A2",
                ],
                "quantity": 1,
                "useReducedRate": false,
                "totalPrice": 130
            }
        ],
        "categorie": "A2",
        "type": "2rouesETformations"
    },
    {
        "nameF": "PASSERELLE A2 EN A",
        "title": "PASSERELLE A",
        "description": "FORMATION",
        "totalPriceFor": 300,
        "servicesinF": [
            {
                "idS": "660a46247d232b5c6d8a42db",
                "nom": "Frais de dossier",
                "tarifNormal": 50,
                "tarifReduit": 20,
                "tva": 20,
                "nomP": "Frais de dossiers",
                "typeS": "administratif",
                "categories": [],
                "quantity": 1,
                "useReducedRate": true,
                "totalPrice": 20
            },
            {
                "idS": "660a47987d232b5c6d8a42dc",
                "nom": "Leçon de conduite",
                "tarifNormal": 60,
                "tarifReduit": 40,
                "tva": 20,
                "nomP": "Leçon de conduites",
                "typeS": "pratique",
                "categories": [
                    "A",
                    "F125",
                    "AM cyclo",
                    "AM quadri"
                ],
                "quantity": 7,
                "useReducedRate": true,
                "totalPrice": 280
            }
        ],
        "categorie": "A",
        "type": "2rouesETformations"
    },
    {
        "nameF": "SCOOTER 125",
        "title": "SCOOTER A1 125",
        "description": "FORMATION",
        "totalPriceFor": 300,
        "servicesinF": [
            {
                "idS": "660a46247d232b5c6d8a42db",
                "nom": "Frais de dossier",
                "tarifNormal": 50,
                "tarifReduit": 20,
                "tva": 20,
                "nomP": "Frais de dossiers",
                "typeS": "administratif",
                "categories": [],
                "quantity": 1,
                "useReducedRate": true,
                "totalPrice": 20
            },
            {
                "idS": "660a47987d232b5c6d8a42dc",
                "nom": "Leçon de conduite",
                "tarifNormal": 60,
                "tarifReduit": 40,
                "tva": 20,
                "nomP": "Leçon de conduites",
                "typeS": "pratique",
                "categories": [
                    "A",
                    "F125",
                    "AM cyclo",
                    "AM quadri"
                ],
                "quantity": 7,
                "useReducedRate": true,
                "totalPrice": 280
            }
        ],
        "categorie": "F125",
        "type": "2rouesETformations"
    },
    {
        "nameF": "CYCLOMOTEUR 50CC",
        "title": "SCOOTER BSR",
        "description": "PERMIS AM",
        "totalPriceFor": 360,
        "servicesinF": [
            {
                "nom": "Frais de dossier",
                "tarifNormal": 50,
                "tarifReduit": 20,
                "tva": 20,
                "nomP": "Frais de dossiers",
                "typeS": "administratif",
                "categories": [],
                "quantity": 1,
                "useReducedRate": false,
                "totalPrice": 50
            },
            {
                "nom": "Leçon de conduite",
                "tarifNormal": 60,
                "tarifReduit": 40,
                "tva": 20,
                "nomP": "Leçon de conduites",
                "typeS": "pratique",
                "categories": [
                    "A",
                    "F125",
                    "AM cyclo",
                    "AM quadri"
                ],
                "quantity": 7,
                "useReducedRate": true,
                "totalPrice": 280
            },
            {
                "nom": "Sensibilisation aux risques",
                "tarifNormal": 60,
                "tarifReduit": 30,
                "tva": 20,
                "nomP": "Sensibilisation aux risques",
                "typeS": "pratique",
                "categories": [
                    "AM cyclo",
                    "AM quadri"
                ],
                "quantity": 1,
                "useReducedRate": true,
                "totalPrice": 30
            }
        ],
        "categorie": "AM cyclo",
        "type": "bsr"
    },
    {
        "nameF": "QUADRICYCLE LEGER",
        "title": "Quadricycle léger à moteur",
        "description": "PERMIS AM",
        "totalPriceFor": 360,
        "servicesinF": [
            {
                "idS": "660a46247d232b5c6d8a42db",
                "nom": "Frais de dossier",
                "tarifNormal": 50,
                "tarifReduit": 20,
                "tva": 20,
                "nomP": "Frais de dossiers",
                "typeS": "administratif",
                "categories": [],
                "quantity": 1,
                "useReducedRate": false,
                "totalPrice": 50
            },
            {
                "idS": "660a47987d232b5c6d8a42dc",
                "nom": "Leçon de conduite",
                "tarifNormal": 60,
                "tarifReduit": 40,
                "tva": 20,
                "nomP": "Leçon de conduites",
                "typeS": "pratique",
                "categories": [
                    "A",
                    "F125",
                    "AM cyclo",
                    "AM quadri"
                ],
                "quantity": 7,
                "useReducedRate": true,
                "totalPrice": 280
            },
            {
                "idS": "660a49b07d232b5c6d8a42dd",
                "nom": "Sensibilisation aux risques",
                "tarifNormal": 60,
                "tarifReduit": 30,
                "tva": 20,
                "nomP": "Sensibilisation aux risques",
                "typeS": "pratique",
                "categories": [
                    "AM cyclo",
                    "AM quadri"
                ],
                "quantity": 1,
                "useReducedRate": true,
                "totalPrice": 30
            }
        ],
        "categorie": "AM quadri",
        "type": "bsr"
    }
];
export default formations;