import React from 'react';
import CompPresentation from './components/CompPresentation';
import LesFormules from './components/LesFormules';
import InfosPresAE from './components/InfosPresAE';
import CriteresAE from './components/CriteresAE';
import Horaires from './components/Horaires';
import AvisGoogle from './components/AvisGoogle';
import ContactBan from './components/ContactBan';
import Header from './components/Header';
import Footer from './components/Footer';

function App() {
  return (
    <React.Fragment>
      
      <Header />
      <CompPresentation />
      <InfosPresAE />
      <CriteresAE />
      <LesFormules />
      <Horaires />
      <AvisGoogle />
      <ContactBan />
      <Footer />

    </React.Fragment>
  );
}

export default App;
